import React from "react";
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import emailjs from "@emailjs/browser"

import {settings} from "../utils";
import HeadHelmet from "../components/HeadHelmet/HeadHelmet";

const submitForm = (e,navigate) => {
  e.preventDefault();

  //const form = document.getElementById("contact_form");
  emailjs.sendForm(settings.serviceId, settings.templateId, e.target, settings.publicKey )
    .then(function (response) {
      console.log('SUCCESS!', response.status, response.text);
      navigate("/thank-you/")
    }, function (error) {
      console.log('FAILED...', error);
      navigate("/error/")
    });
};

const Contact = () => {
  const { t } = useTranslation();
  const { navigate } = useI18next();

  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style_snore"
        }}
      >
        <HeadHelmet
          title='contact_title'
          description='contact_subtitle'
        />
        <div className="inner-banner pt-29 pb-md-11 bg-default-2">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9 heading-color">{t('contact_title')}</h2>
                  <p className="gr-text-8 mb-13 text-color-opacity">
                    {t('contact_subtitle')}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="contact-section pb-5 pb-md-29 bg-default-2 ">
          <Container>
            <Row className="justify-content-center">
              <Col
                sm="5"
                md="6"
                lg="5"
                xl="5"
                className="offset-lg-1 d-flex flex-column justify-content-center mb-9 order-sm-2"
              >
                <div className="single-contact-widget mb-13">
                  <h3 className="gr-text-6 mb-5 heading-color">{t('contact_call_us')}</h3>
                  <p className="gr-text-7 mb-0 text-color-opacity">
                    +41 (0) 44 558 20 28 <br />
                    +41 (0) 76 689 59 92
                  </p>
                </div>
                <div className="single-contact-widget mb-13">
                  <h3 className="gr-text-6 mb-5 heading-color">{t('contact_email_us')}</h3>
                  <p className="gr-text-7 mb-0 text-color-opacity">
                    info@snorefreeswiss.ch
                    <br />
                  </p>
                </div>
                <div className="single-contact-widget">
                  <h3 className="gr-text-6 mb-5 heading-color">{t('contact_visit_us')}</h3>
                  <p className="gr-text-7 mb-0 text-color-opacity">
                    Herostrasse 9
                    <br />
                    CH-8048 Zürich
                  </p>
                </div>
              </Col>
              <Col sm="7" md="6" lg="5" xl="4" className="order-sm-1">
                <div className="login-form bg-white border-gray-3 px-7 pt-8 pb-9 shadow-1 rounded-10">
                  <form
                    id="contact_form"
                    name="contact_form"
                    // method="post"
                    // data-netlify="true"
                    // data-netlify-honeypot="bot-field"
                    onSubmit={(e)=>submitForm(e,navigate)}
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" id="to_name" name="to_name" value="ProthesLab3D" />
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        {t('contact_name')}
                      </label>
                      <input
                        className={"form-control px-7 gr-text-11 mt-3 bg-white border"}
                        type="text"
                        id="from_name"
                        name="from_name"
                        placeholder={t('contact_name_ph')}
                        required
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        {t('contact_email')}
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 mt-3 bg-white border"
                        type="text"
                        id="from_email"
                        name="from_email"
                        placeholder={t('contact_email_ph')}
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        required
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        {t('contact_phone')}
                      </label>
                      <input
                        className="form-control px-7 gr-text-11 border mt-3 bg-white"
                        type="text"
                        id="from_phone"
                        name="from_phone"
                        placeholder={t('contact_phone_ph')}
                        pattern="\+?[\d,\s]*$"
                        required
                      />
                    </div>
                    <div className="form-group mb-6">
                      <label
                        htmlFor="name"
                        className="gr-text-11 fw-bold text-blackish-blue"
                      >
                        {t('contact_message')}
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        className="form-control px-7 gr-text-11 border mt-3 bg-white"
                        placeholder={t('contact_message_ph')}
                        required
                      ></textarea>
                    </div>
                    <div className="button-block mb-2">
                      <button className="btn btn-primary w-100" >
                        {t('contact_send')}
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
